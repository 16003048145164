import React from "react";
import { Navbar as BootstrapNavbar, Container, Nav } from 'react-bootstrap';
import classNames from "classnames";
import classes from "./navbar.module.css";
import BrandLogo from "../../images/logo.png";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            yOffset: 0
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        const newOffset = window.pageYOffset;
        const prevOffset = this.state.yOffset;

        const visible = newOffset < 10 || prevOffset > newOffset;

        this.setState({
            visible,
            yOffset: newOffset
        });
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        const { visible } = this.state;
        return (
            <BootstrapNavbar
                expand="lg"
                fixed="top"
                className={classNames(classes.customNav, "py-0", { [classes.customNavHidden]: !visible })}
                collapseOnSelect={true}
            >
                <Container>
                    <BootstrapNavbar.Brand href="#home">
                        <img
                            src={BrandLogo}
                            height="60"
                            className="d-inline-block align-top m-0"
                            alt="Main Logo"
                        />
                    </BootstrapNavbar.Brand>

                    <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />

                    <BootstrapNavbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <Nav.Link href="#home" className={classes.navLink}>Home</Nav.Link>
                            <Nav.Link href="#about" className={classes.navLink}>About</Nav.Link>
                            <Nav.Link href="#projects" className={classes.navLink}>Projects</Nav.Link>
                            <Nav.Link href="#portfolio" className={classes.navLink}>Portfolio</Nav.Link>
                            <Nav.Link href="#contact" className={classes.navLink}>Contact</Nav.Link>
                        </Nav>
                    </BootstrapNavbar.Collapse>
                </Container>
            </BootstrapNavbar>
        )

    };
};

export default Navbar;