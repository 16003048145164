import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import socials from "../../utils/socials";
import { RiHeart2Line } from "react-icons/ri";

import classes from "./footer.module.css";

const Footer = () => {    
    return (
        <footer className="mt-5">
            <Container className="flex-column justify-content-center text-center pt-1">
                <h3>Follow Me</h3>
                <Row className="container d-flex justify-content-center my-3">
                    {
                        socials.map((social, index) => {
                            return (
                                <Col xs={3} key={index}>
                                    <a href={social.link} className={classes.socialLink} target="_blank" rel="noreferrer">
                                        {social.icon}
                                    </a>
                                </Col>
                            )
                        })
                    }
                </Row>

                <small className="mt-3">
                    Copyright © {new Date().getFullYear()} All rights reserved |
				Made with <RiHeart2Line color="red" /> by <span style={{ color: "#854fee" }}>Ishan Baniya</span>
                </small>
            </Container>
        </footer>
    )
};

export default Footer;