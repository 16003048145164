import React from "react";
import { RiFacebookLine, RiInstagramLine, RiLinkedinLine, RiMailLine } from "react-icons/ri";

const socials = [
    { link: "https://www.facebook.com/ibaniya1998", icon: <RiFacebookLine /> },
    { link: "https://www.instagram.com/ibaniya/", icon: <RiInstagramLine /> },
    { link: "https://www.linkedin.com/in/ibaniya", icon: <RiLinkedinLine /> },
    { link: "mailto:me@ishanbaniya.com", icon: <RiMailLine /> },
];

export default socials;